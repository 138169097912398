import _ from "lodash";

export const therapyNotInGER = (therapyProtocol) => {
    if(!therapyProtocol.protocol) return false
    return _.find(therapyProtocol.protocol.drugs, (p) => p.available === false)
}

export const openBrowser = (url) => {
  window.open(url, "_blank");
}

export const surveysOfTherapyProtocol = (therapyProtocol) => {
  let therapySurveys = []
  if(therapyProtocol.meta && therapyProtocol.meta.surveys && therapyProtocol.meta.surveys.length > 0) {
    for(const tp of therapyProtocol.meta.surveys) {
      therapySurveys.push(tp.survey)
    }
  }
  return therapySurveys
}
