<template>
  <div>
    <span v-for="split in splitted_description" :key="split">

      <span v-if="split.type == 'image'">
        <!-- <img :src="('https://dacyhelp.s2.giria.io/dacy-client/' + split.content + '.jpg')"/> -->

        <el-image
          class="m-1"
          style="width: 20px; height: 20px;"
          :src="('https://dacyhelp.s2.giria.io/dacy-client/placeholder.png')"
          fit="cover"
          :preview-teleported="true"
          :preview-src-list="['https://dacyhelp.s2.giria.io/dacy-client/' + split.content + '.jpg']">
            <template #placeholder>
              <div class="image-slot">Lädt<span class="dot">...</span></div>
            </template>
          </el-image>

      </span>
      <span v-else>{{split.content}}</span>
    </span>
  </div>
</template>

<script>
export default {
  name: "StudyResultDescription",
  props: ['description', 'literature'],
  components: {},
  data() {
    return {
      splitted_description: []
    }
  },
  mounted: async function () {
    this.splitDescription()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    splitDescription() {

      const graph_placeholder_regex = /(;;[0-9]+;;)+/g

      if(!this.description) return

      const graph_placeholders = this.description.match(graph_placeholder_regex)
      let last_split = ''
      if(graph_placeholders && graph_placeholders.length > 0) {
        for(const graph_placeholder of graph_placeholders) {
          const graph_id = parseInt(graph_placeholder.replaceAll(';', ''))

          const splits = this.description.split(graph_placeholder)
          this.splitted_description.push({
            type: 'text',
            content: splits[0].replace(last_split, '').replace(graph_placeholder_regex, '')
          })

          last_split = splits[0]

          for(const literature of this.literature) {
            for(const graph of literature.graphs) {
              if(graph.id == graph_id) {
                this.splitted_description.push({
                  type: 'image',
                  content: graph.filename
                })
                break
              }
            }
          }

        }
      }
      else {
        this.splitted_description.push({
          type: 'text',
          content: this.description
        })
      }

    }
  }
}
</script>
<style scoped>
</style>
